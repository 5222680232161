<template>
    <!-- <div v-if="loading" class="flex justify-center mt-32">
        <svg class="animate-spin ml-3 h-10 w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
    </div> -->
    <div class="payment-status">
        <div class="logo-wrapper">
            <div v-if="pageStatus === 'pending'"
                 class="pending">
                <div class="logo-inner" >
                    <div class="sjef-logo">
                        <img src="../../assets/img/logos/sjef_logo-white.png" alt="">
                        <div class="snippet" data-title=".dot-pulse">
                            <div class="stage">
                                <div class="dot-pulse"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-container py-5">
                <!-- paid/deferred -->
                <!-- <div v-if="pageStatus == 'paid' || payment_status == 'confirmed' || payment_status == 'deferred'">
                    <div class="text-center pt-5">
                        <h2 class="text-3xl font-bold">{{ $t('paymentstatus.success.title') }}</h2>
                    </div>
                    <div class="payment-icon-wrapper">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0ABF53" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M17 9L11 15" stroke="#0ABF53" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8 12L11 15" stroke="#0ABF53" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>

                    <div class="flex items-center justify-center pt-2 rounded-b">
                        <span class="inline-flex rounded-sm shadow-sm">
                            <router-link :to="{ name: 'confirmation', params: {location_slug: location_slug, transaction_id: this.transaction_id}}"
                                        class="inline-flex justify-center items-center mt-3 px-6 py-3 btn btn-primary text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150">
                                {{ $t('paymentstatus.success.button') }}
                            </router-link>
                        </span>
                    </div>
                    <div v-if="adyenGiving.active === true" id='donation-container' class="radius-anchor"></div>
                </div> -->

                <!-- open/pending -->

                <div v-if="pageStatus === 'open' || pageStatus === 'pending'">

                    <div class="text-center pt-5" v-if="checks < checks_maximum">
                        <h2 class="text-3xl font-bold">{{ $t('paymentstatus.pending.title') }}</h2>
                        <h3 class="text-xl font-bold"></h3>
                        <h3 class="text-base">{{ $t('paymentstatus.pending.description') }}</h3>
                    </div>

                    <!-- payment success, transaction pending -->
                    <div class="text-center pt-5" v-else>
                        <h2 class="text-3xl font-bold">{{ $t('paymentstatus.success-pending.title') }}</h2>
                        <h3 class="text-xl font-bold"></h3>
                        <h3 class="text-base">{{ $t('paymentstatus.success-pending.description') }}</h3>

                        <div class="h-20vh">
                            <div class="flex items-center justify-center pt-2 rounded-b">
                                <span class="inline-flex rounded-sm shadow-sm">
                                    <button @click="reloadPage()"
                                            class="inline-flex justify-center items-center mt-3 px-6 py-3 btn btn-primary text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150">
                                        {{ $t('paymentstatus.pending.check_status') }}
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- canceled -->
                <div v-else-if="pageStatus == 'canceled'">
                    <div class="text-center pt-5">
                        <h2 class="text-2xl font-bold mb-3">{{ $t('paymentstatus.canceled.title') }}</h2>
                        <h3 class="text-lg">{{ $t('paymentstatus.canceled.subtitle') }}</h3>
                    </div>

                    <div class="payment-icon-wrapper">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                                stroke="#E50000" stroke-width="2" stroke-miterlimit="10" />
                            <path d="M14 8L8 14" stroke="#E50000" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" />
                            <path d="M8 8L14 14" stroke="#E50000" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" />
                        </svg>
                    </div>

                    <div class="flex items-center justify-center pt-2 rounded-bs">
                        <div class="relative">
                            <button @click="tryAgainRedirect()"
                                    class="w-full inline-flex justify-center items-center mt-3 px-6 py-3 btn btn-primary text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150">
                                {{ $t('paymentstatus.canceled.button') }}
                            </button>
                        </div>
                    </div>
                </div>

                <!-- failed -->
                <div v-if="pageStatus == 'failed'">
                    <div class="text-center pt-5">
                        <h2 class="text-2xl font-bold">{{ $t('paymentstatus.failed.title') }}</h2>
                        <h3 class="text-lg">{{ $t('paymentstatus.failed.subtitle') }}</h3>
                    </div>

                    <div class="payment-icon-wrapper">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                                stroke="#E50000" stroke-width="2" stroke-miterlimit="10" />
                            <path d="M14 8L8 14" stroke="#E50000" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" />
                            <path d="M8 8L14 14" stroke="#E50000" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" />
                        </svg>
                    </div>

                    <div class="flex items-center justify-center pt-2 rounded-bs">
                        <div class="relative">
                            <button @click="tryAgainRedirect()"
                                    class="w-full inline-flex justify-center items-center mt-3 px-6 py-3 btn btn-primary text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150">
                                {{ $t('paymentstatus.canceled.button') }}
                            </button>
                        </div>
                    </div>
                </div>

                <!-- unknown -->
                <div v-else-if="pageStatus == 'unknown'">
                    <div class="text-center pt-5">
                        <h2 class="text-2xl font-bold mb-3">{{ $t('paymentstatus.unknown.title') }}</h2>
                        <h3 class="text-lg">{{ $t('paymentstatus.unknown.subtitle') }}</h3>
                    </div>

                    <!-- <div class="payment-icon-wrapper">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                                stroke="#E50000" stroke-width="2" stroke-miterlimit="10" />
                            <path d="M14 8L8 14" stroke="#E50000" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" />
                            <path d="M8 8L14 14" stroke="#E50000" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" />
                        </svg>
                    </div> -->

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// libs
import axios from 'axios';
import * as _api from '../../lib/api';
import * as _state from '../../lib/state';
import * as _transaction from "../../lib/transaction";
import * as _paymentAdyen from '../../lib/payment/adyen';

export default {
    name: 'PaymentStatus',
    props: ['location_slug', 'transaction_id', 'payment_id', 'data'],
    components: {},
    data () {
        return {
            checks: 0,
            checks_maximum: 2,
            interval: false,
            interval_time: 5000,
            payment_status: 'pending',
            transaction_status: null,
            transaction_method: null,
            transaction_type: null,
        };
    },
    beforeMount () {
        document.body.classList.add('no-page-animation');
    },
    async mounted () {
        axios.defaults.headers.common['Location'] = this.location_slug;

        // initial wait for webhook
        await new Promise(resolve => setTimeout(resolve, 1200));

        // initial check
        let status = await this.getPaymentStatus();

        if (status === false) {
            // check status on interval
            let self = this;
            this.interval = setInterval(() => {
                self.checkStatus();
            }, this.interval_time);
        }
    },
    methods: {

        checkStatus () {
            this.checks++;

            this.getPaymentStatus();

            if (this.checks >= this.checks_maximum) {
                clearInterval(this.interval);
            }
        },

        async getPaymentStatus () {
            // set the payment data
            let payment_data = {};

            if (this.$route.query.redirectResult) {
                payment_data = {
                    redirectResult: this.$route.query.redirectResult,
                };
            }

            if (this.$route.query.status) {
                payment_data = {
                    status: this.$route.query.status,
                };
            }
            if (this.$route.query.data) {
                payment_data = this.$route.query.data
            }

            // get the status of the payment
            // todo: needs testing
            const response = await _api.post('payments/:payment_id/status', { payment_data }, this.payment_id);

            this.payment_status = response.payment_status;
            this.transaction_status = response.transaction_status; // remove?
            this.transaction_method = response.transaction.attributes.method; // remove?
            this.transaction_type = response.transaction.attributes.type; // remove?

            if (this.payment_status == 'confirmed' || this.payment_status == 'paid' || this.payment_status == 'deferred' || this.payment_status == 'received') {
                _transaction.clear(false);
            }

            if (response.donations === true) {
                _paymentAdyen.initDonation(this.payment_id);
            }

            if (this.pageStatus === 'completed') {
                _transaction.clear(false);

                clearInterval(this.interval);

                this.$router.push({
                    name: 'confirmation',
                    params: { location_slug: this.location_slug, transaction_id: response.transaction_id },
                });

                return true;
            }

            return false;
        },

        tryAgainRedirect () {
            // todo: redirect table
            // if (this.transaction.attributes.type == 'tablepayment') {
            // this.$router.push('/' + this.location_slug + '/tafel/afrekenen');

            // return;
            // }
            if (this.transaction_method === 'creditwallet') {
                return this.$router.push('/' + this.location_slug + '/wallet/opwaarderen');
            }
            if (this.transaction_type === 'tablepayment') {
                return this.$router.push('/' + this.location_slug + '/tafel/afrekenen');
            }
            return this.$router.push('/' + this.location_slug + '/bestelling/plaatsen');
        },


        reloadPage () {
            window.location.reload(true);
        },
    },

    computed: {
        adyenGiving () {
            return this.config.settings.adyen_giving;
        },

        config () {
            return _state.get('config/getConfig');
        },

        pageStatus () {
            if ((['received']).includes(this.payment_status)) {
                return 'unknown';
            }
            if ((['paid', 'confirmed', 'deferred']).includes(this.payment_status) === false) {
                return this.payment_status;
            }

            if (this.transaction_status === 'completed' || this.transaction_status === 'deferred') {
                return 'completed';
            }

            return 'pending';
        },
    },
};
</script>
